@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Lato", sans-serif;
}

@media (max-width: 767px) {
  .chatInput {
    bottom: 100px;
  }
  .chatArea {
    height:500px;
  }
}
:root {
  --vh: 100vh;
}

.container {
  height: calc(var(--vh) * 1);
}
/*#root {*/
/*  height: calc(var(--vh) * 100);*/
/*}*/

.container input:checked~.checkmark {
  background-color: #60b7c4;
}

.container input:checked~.checkmark:after {
  display: block;
}

.container .checkmark:after {
  border: solid white;
  border-width: 0 2px 2px 0;
}

.container input:checked~.radio {
  background-color: #60b7c4;
}

.container input:checked~.radio:after {
  display: block;
}

.container .radio:after {
  border-radius: 50%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #c0c7ce;
  border-radius: 7px;
}

.user-chat-show {
  transform: translateX(0);
  visibility: visible;
}

.chat-recorder .audio-recorder {
  background: transparent;
  box-shadow: none;
  padding: 0;
  width: 20px;
}

.chat-recorder .audio-recorder .audio-recorder-mic {
  padding: 0;
  height: 18px;
  width: 18px;
  opacity: 0.6;
}

.chat-recorder:has(.audio-recorder.recording) {
  width: calc(100% - 84px);
  z-index: 10;
}

.chat-recorder .audio-recorder.recording {
  width: 100%;
}

.recording-recorder .audio-recorder {
  width: 100%;
}

.recording-recorder .audio-recorder .audio-recorder-mic {
  height: 30px;
  width: 30px;
  opacity: 0.8;
}

.recording-recorder:has(.audio-recorder.recording) {
  width: 100%;
}

audio::-webkit-media-controls-panel {
  background-color: #fff;
}

.chat-img {
  padding: 10px !important;
  max-width: 300px !important;
  border-radius: 12px 12px 0 12px;
}

.pricing-circle-animation {
  position: absolute;
  top: 40%;
  left: 20%;
  height: 20px;
  width: 20px;
  animation: zoom-in-zoom-out 2s ease-out infinite;
}

.pricing-circle-animation1 {
  position: absolute;
  bottom: 20%;
  right: 10%;
  height: 40px;
  width: 40px;
  animation: zoom-in-zoom-out 2s ease-out infinite;
}

.pricing-circle-animation2 {
  position: absolute;
  top: -20px;
  left: -20px;
  height: 80px;
  width: 80px;
  animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.5, 1.5);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0.5;
  }
}

.recording-table tr {
  border-bottom: 1px solid rgb(229 231 235);
}

.recording-table .pagination ul {
  display: flex;
  align-items: center;
  border-radius: 8px;
  gap: 1px;
}

.recording-table .pagination ul li {
  border-radius: 4px;
  border: 1px solid transparent;
}

.recording-table .pagination ul li a {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.recording-table .pagination ul li.selected {
  color: #fff;
  background-color: rgba(95, 182, 196, 0.8);
}

.recording-table .pagination ul li.previous:hover,
.recording-table .pagination ul li.next:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width:991px) {
  .user-chat-show {
    transform: translateX(0);
    visibility: visible;
  }
}

@media screen and (max-width: 767px) {
  .chat-recorder:has(.audio-recorder.recording) {
    width: calc(100% - 45px);
  }
}

@media screen and (max-width: 500px) {
  .pricing-circle-animation1 {
    bottom: -15%;
    right: -10%;
    height: 50px;
    width: 50px;
  }

  .pricing-circle-animation2 {
    height: 60px;
    width: 60px;
  }
}

@media screen and (max-width: 425px) {
  .chat-img {
    padding: 10px !important;
    max-width: 230px !important;
    border-radius: 12px 12px 0 12px;
  }

  .chat-img .sent-img {
    max-width: 250px;
  }
}

.h-custom-height {
  height: calc(100vh - 100px);
}